import React, {useEffect} from 'react'
import '../css/footer.min.css'
import video2 from '../../assets/video2.mp4'
import {FiSend} from 'react-icons/fi'
import {MdTravelExplore} from 'react-icons/md'
import {AiOutlineTwitter, AiFillYoutube, AiFillInstagram} from 'react-icons/ai'
import {FaTripadvisor} from 'react-icons/fa'
import {FiChevronRight} from 'react-icons/fi'

import Aos from 'aos'
import 'aos/dist/aos.css'



const Footer = () => {

  
  useEffect(()=>{
    Aos.init({duration: 2000})
  }, [])

  return (
    <section className='footer'>
      <div className="videoDiv">
        <video src={video2} loop autoPlay muted type='video/mp4'></video>
      </div>

    <div className="secContent container">
      <div className="contactDiv flex">
        <div className="text" data-aos='fade-up'>
          <small>Restons en contact</small>
          <h2>Voyager avec nous</h2>
        </div>

        <div className="inputDiv flex">
          <input data-aos='fade-up' type='text' placeholder='Entrez votre Email'/>
          <button data-aos='fade-up' className='btn flex' type='submit'>
            SEND <FiSend className='icon'/>
          </button>
        </div>
      </div>

      <div className="footerCard flex">
        <div className="footerIntro flex">
          <div className="logoDiv">
            <a href='#' className='logo flex'>
              <MdTravelExplore className='icon'/>  Travel.
            </a>
          </div>

          <div className="footerParagraph">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus, debitis! Repudiandae atque dolorum eligendi rerum non placeat ab eum id facere! Provident quibusdam quasi nam?
          </div>
          <div className="footerSocials" data-aos='fade-up'>
            <AiOutlineTwitter className='icon'/> 
            <AiFillYoutube className='icon'/> 
            <AiFillInstagram className='icon'/> 
            <FaTripadvisor className='icon'/> 
          </div>
        </div>

        <div className="footerLinks grid">
          <div className="linkGroup">
            <span className='groupeTitle'>
              Notre agence
            </span>

            <li className='footerList flex'>
              <FiChevronRight className='icon'/> Services
            </li>
            
            <li className='footerList flex'>
              <FiChevronRight className='icon'/> Agence
            </li>

            <li className='footerList flex'>
              <FiChevronRight className='icon'/> Assurance
            </li>

            <li className='footerList flex'>
              <FiChevronRight className='icon'/> Toursime
            </li>

            <li className='footerList flex'>
              <FiChevronRight className='icon'/> paiement 
            </li>

          </div>
        </div>

        <div className="footerDiv flex">
          <small>Ceci n'est pas un vrai site!</small>
          <small>Copyright <a href='https://portfolio.brice76.fr'>Brice </a>2022</small>
        </div>
      </div>
    </div>


    </section>
  )
}

export default Footer