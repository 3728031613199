import React, {useEffect} from 'react'
import '../css/main.min.css'
import img from '../../assets/finlande.jpg'
import img2 from '../../assets/canada.jpg'
import img3 from '../../assets/island.jpg'
import { HiOutlineLocationMarker, HiOutlineClipboardCheck } from 'react-icons/hi'

import Aos from 'aos'
import 'aos/dist/aos.css'

const Data = [
  {
    id: 1,
    imgSrc: img,
    destTitle: 'Helsinki',
    location: 'Finlande',
    grade: 'CULTURAL RELAX',
    fees: '700€',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation'
  },

  {
    id: 2,
    imgSrc: img2,
    destTitle: 'Vancouver',
    location: 'Canada',
    grade: 'CULTURAL RELAX',
    fees: '1100€',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation'
  },

  {
    id: 3,
    imgSrc: img3,
    destTitle: 'Reykjavik',
    location: 'Islande',
    grade: 'CULTURAL RELAX',
    fees: '1200€',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation'
  },

  {
    id: 4,
    imgSrc: img,
    destTitle: 'Helsinki',
    location: 'Finlande',
    grade: 'CULTURAL RELAX',
    fees: '700€',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation'
  },

  {
    id: 5,
    imgSrc: img2,
    destTitle: 'Vancouver',
    location: 'Canada',
    grade: 'CULTURAL RELAX',
    fees: '1100€',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation'
  },

  {
    id: 6,
    imgSrc: img3,
    destTitle: 'Reykjavik',
    location: 'Islande',
    grade: 'CULTURAL RELAX',
    fees: '1200€',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation'
  },
]

const Main = () => {

  useEffect(()=>{
    Aos.init({duration: 2000})
  }, [])

  return (
    <section className='main container section'>
      <div className="secTitle">
        <h3 className='title' data-aos='fade-right'>Les destinations les plus visited</h3>
      </div>

      <div className="secContent grid">

        {
          Data.map(({ id, imgSrc, destTitle, location, grade, fees, description }) => {
            return (
              <div key={id} data-aos='fade-up' className="singleDestination">
                {/* la clé pour return un id du map array */}
                <div className="imageDiv">
                  <img src={imgSrc} alt={destTitle} />
                </div>

                <div className="cardInfo">
                  <h4 className='destTitle'>{destTitle}</h4>
                  <span className='continent flex'>
                    <HiOutlineLocationMarker className='icon' />
                    <span className='name'>{location}</span>
                  </span>

                  <div className="fees flex">
                    <div className="grade">
                      <span>{grade}<small>+1</small></span>
                    </div>
                    <div className="price">
                      <h5>{fees}</h5>
                    </div>
                  </div>

                  <div className="desc">
                    <p>{description}</p>
                  </div>

                  <button className='btn flex'>
                    DETAILS <HiOutlineClipboardCheck className='icon' />
                  </button>
                </div>
              </div>
            )
          })
        }
      </div>
    </section>
  )
}

export default Main