import React, {useState} from 'react'
import '../css/navbar.min.css'
import { MdTravelExplore } from 'react-icons/md';
import { AiFillCloseCircle } from 'react-icons/ai';
import {TbGridDots} from 'react-icons/tb';

const Navbar = () => {
    const[active, setActive] = useState('navBar')
    //function show nav
    const showNav = () =>{
        setActive('navBar activeNavbar')
        console.log('show');
    }

    //function remove nav
    const removeNav = () =>{
        setActive('navBar')
        console.log('Notshow');
    }

    return (
        <section className='navBarSection'>
            <header className='header flex'>
                <div className="logoDiv">
                    <a href='#' className='logo flex'>
                        <h1><MdTravelExplore className='icon' /> Travel.</h1>
                    </a>
                </div>

                <div className={active}>
                    <ul className="navLists flex">
                        <li className="navItem">
                            <a href="#" className='navLink'>Home</a>
                        </li>
                        <li className="navItem">
                            <a href="#" className='navLink'>Packages</a>
                        </li>
                        <li className="navItem">
                            <a href="#" className='navLink'>Shop</a>
                        </li>
                        <li className="navItem">
                            <a href="#" className='navLink'>About</a>
                        </li>
                        <li className="navItem">
                            <a href="#" className='navLink'>Pages</a>
                        </li>
                        <li className="navItem">
                            <a href="#" className='navLink'>News</a>
                        </li>
                        <li className="navItem">
                            <a href="#" className='navLink'>Contact</a>
                        </li>

                        <button className='btn'>
                            <a href='#'> BOOK NOW</a>
                        </button>
                    </ul>

                    <div onClick={removeNav} className="closeNavbar">
                        <AiFillCloseCircle className="icon" />
                    </div>
                </div>

                <div onClick={showNav} className="toggleNavBar">
                <TbGridDots className="icon"/>
                </div>
            </header>
        </section>
    )
}

export default Navbar